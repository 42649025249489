import { motion } from "framer-motion";
import React from "react";
import { BsGithub, BsLinkedin, BsFacebook } from "react-icons/bs";

const SocialLinksContainer = () => {
  const socialLinks = [
    {
      name: "Github",
      link: "https://github.com/zmumbauer",
      icon: <BsGithub />,
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/zmumbauer/",
      icon: <BsLinkedin />,
    },
    // {
    //   name: "Hackerrank",
    //   link: "",
    //   icon: FaHackerrank,
    // },
  ];
  return (
    <>
      <div className="flex flex-row flex-nowrap p-5 place-content-around brightness-0 dark:invert grayscale">
        {socialLinks.map(({ name, link, icon }) => {
          return <SocialIcon key={name} name={name} link={link} icon={icon} />;
        })}
      </div>
    </>
  );
};

const SocialIcon = ({ name, link, icon }: ISocialIconProps) => {
  return (
    <>
      <motion.a
        href={link}
        target="_blank"
        whileHover={{
          rotateY: 360 * 500,
          transition: {
            duration: 2 * 500,
            repeat: Infinity,
            ease: "linear",
          },
        }}
      >
        <div className="rounded-full bg:black mx-10 text-3xl dark">{icon}</div>
      </motion.a>
    </>
  );
};

interface ISocialIconProps {
  name: string;
  link: string;
  icon: React.ReactNode;
}
export default SocialLinksContainer;
