import React from "react";
import SEO from "../components/layout_elements/SEO";
import PageLanding from "../components/PageLanding";
import TextContainer from "../components/TextContainer";
import SocialLinksContainer from "../components/SocialLinksContainer";

const Contact = () => {
  return (
    <>
      <SEO title="Contact Me | Zach Mumbauer" />
      <PageLanding>Contact Me</PageLanding>
      <TextContainer>
        <div className="text-center">We can change the world together.</div>

        <div className="w-full mx-auto">
          <SocialLinksContainer />
        </div>
      </TextContainer>
    </>
  );
};

export default Contact;
